/* eslint-disable no-unused-vars */
import cryptoJS from "crypto-js";

import request from "./request";
import scaleTradeRequest from "./requestAPI";

import { environment, key, externalEncryptionKey } from "../config/index";

const encryptionKey = process.env.EXTERNAL_ENCRYPTION_KEY
  ? process.env.EXTERNAL_ENCRYPTION_KEY
  : externalEncryptionKey;

const UserWrite =
  environment === "DEV"
    ? process.env.ADMIN
      ? process.env.ADMIN
      : key
    : process.env.USER_WRITE;
const UserRead =
  environment === "DEV"
    ? process.env.ADMIN
      ? process.env.ADMIN
      : key
    : process.env.USER_READ;
const SecurityWrite =
  environment === "DEV"
    ? process.env.ADMIN
      ? process.env.ADMIN
      : key
    : process.env.SECURITY_WRITE;
const SecurityRead =
  environment === "DEV"
    ? process.env.ADMIN
      ? process.env.ADMIN
      : key
    : process.env.SECURITY_READ;
const StrategyWrite =
  environment === "DEV"
    ? process.env.ADMIN
      ? process.env.ADMIN
      : key
    : process.env.STRATEGY_WRITE;
const StrategyRead =
  environment === "DEV"
    ? process.env.ADMIN
      ? process.env.ADMIN
      : key
    : process.env.STRATEGY_READ;
const OrderRead =
  environment === "DEV"
    ? process.env.ADMIN
      ? process.env.ADMIN
      : key
    : process.env.ORDER_READ;
const OrderWrite =
  environment === "DEV"
    ? process.env.ADMIN
      ? process.env.ADMIN
      : key
    : process.env.ORDER_WRITE;

const verifyScaleTradeToken = async (data) => {
  return new Promise(async (resolve, reject) => {
    const decToken = cryptoJS.AES.decrypt(data, "Key");
    const originalToken = JSON.parse(decToken.toString(cryptoJS.enc.Utf8));
    resolve(originalToken);
  });
};

const generateToken = async (data) => {
  return new Promise(async (resolve, reject) => {
    const saltTest = cryptoJS.lib.WordArray.random(16);

    var encrypted = cryptoJS.AES.encrypt(
      data,
      encryptionKey + saltTest.toString()
    );

    encrypted = encrypted.toString();

    resolve(encrypted + "$" + saltTest.toString());
  });
};

/* Authentication */
// Auth Registration
export const authRegister = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("auth/register", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Auth Registration Email Resend
export const authRegisterEmailResend = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("auth/email/resend", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Auth Registration Email Verify
export const authRegisterEmailVerify = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("auth/email/verify", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Auth Login
export const authLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Auth Login Send OTP
export const authLoginSendOTP = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("auth/send-otp", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Auth Reset Password
export const authResetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("auth/password", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Auth Update Password
export const authUpdatePassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("auth/forgot/password", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Auth Forgot Password
export const authForgotPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("auth/forgot/password", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Auth Handle Logout
export const authLogout = (data) => {
  return new Promise(async (resolve, reject) => {
    localStorage.clear();
    resolve({
      status: true,
      message: "You have successfully logged out of your ScaleTrade account.",
      data: null,
    });
  });
};

/* Profile */
// Profile Update
export const profileUpdate = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("profile", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Profile Update Connected Account
export const profileUpdateConnectedAccount = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("profile/account", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Profile Get
export const profileGet = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("profile")
      .then(async (res) => {
        let data = await verifyScaleTradeToken(res?.data);
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Profile Delete
export const profileDelete = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete("profile")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// CustomerIO
export const customerIO = (type) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`profile/customerIO/${type}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Check Token
export const checkToken = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/auth/check-token")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// User Email Subscribe
export const userSubscribeEmail = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("profile/receiveNotification", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* SUBSCRIPTION */
// Delete Subscription
export const deleteSubscription = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/subscription`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Subscription
export const updateSubscription = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/subscription/update-details`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* SUPPORT TICKET */
export const newSupportTicket = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`/support`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* BROKERAGE NOT FOUND */
export const brokerageNotFound = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`/brokerage`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* USER NOTIFICATION */
// ADD USER NOTIFICATION
export const addUserNotification = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("user-notification", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// UPDATE USER NOTIFICATION
export const editUserNotification = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`user-notification/${id}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// MARK ALL AS READ
export const markAsReadUserNotification = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`user-notification/`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// GET USER NOTIFICATIONS
export const getUserNotification = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("user-notification")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* VERION - 2 API */

/* ACCOUNT MANAGEMENT API */
// TEMPORARY WEBHOOK
export const temporaryWebhook = (userId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${UserWrite}&userId=${userId}`
    );
    await scaleTradeRequest
      .get("user/account/list/external/snaptrade", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET ACCOUNT LIST
export const getAccountListApi = (userId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${UserWrite}&userId=${userId}`
    );
    await scaleTradeRequest
      .get("user/account/list", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET AUTHORIZATION LINK
export const getAuthorizationLinkApi = (userId, brokerage) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${UserWrite}&userId=${userId}&brokerage=${brokerage}`
    );
    await scaleTradeRequest
      .post(
        "user/auth/link",
        {},
        {
          headers: { token: updatedToken },
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET RECONNECT LINK
export const getReconnectLinkApi = (userId, accountId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${UserWrite}&userId=${userId}&accountId=${accountId}`
    );
    await scaleTradeRequest
      .post("user/reconnect/link", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// ADD USER ACCOUNT
export const addUserAccountApi = (userId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${UserWrite}&userId=${userId}`
    );
    await scaleTradeRequest
      .post(
        "user/add/account",
        {},
        {
          headers: { token: updatedToken },
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// REMOVE USER ACCOUNT BROKERAGE
export const removeUserAccountBrokerageApi = (userId, accountId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${UserWrite}&userId=${userId}&accountId=${accountId}`
    );
    await scaleTradeRequest
      .post(
        "user/remove/account",
        {},
        {
          headers: { token: updatedToken },
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// REMOVE USER ACCOUNT
export const removeUserAccountApi = (userId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${UserWrite}&userId=${userId}`
    );
    await scaleTradeRequest
      .post("user/remove/user", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* ACCOUNT INFORMATION API */
// GET ACCOUNT DETAILS
export const getAccountDetailsApi = (userId, accountId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${UserRead}&userId=${userId}&accountId=${accountId}`
    );
    await scaleTradeRequest
      .get("user/get/details/account", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET ACCOUNT DETAILS
export const getAccountAllDetailsApi = (userId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${UserRead}&userId=${userId}`
    );
    await scaleTradeRequest
      .get("user/get/details/all", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* SECURITY INFORMATION API */
// GET AUTOMATION INFORMATION
export const getAutomationInformationApi = () => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(`key=${SecurityRead}`);
    await scaleTradeRequest
      .get("securities/get/automation/information/all", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET DIVIDEND INFORMATION
export const getDividendInformationApi = (data) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(`key=${SecurityRead}`);
    await scaleTradeRequest
      .post("securities/get/dividend/information/all", data, {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET BENCHMARK INFORMATION SINGLE
export const getBenchmarkInformationAPI = (underlying) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${SecurityRead}&underlying=${underlying}`
    );
    await scaleTradeRequest
      .get("securities/get/benchmark/information/single", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET ACTIVE ALL
export const getActiveAllApi = () => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(`key=${SecurityRead}`);
    await scaleTradeRequest
      .get("securities/get/active/all", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET ACTIVE POPULAR
export const getActivePopularApi = () => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(`key=${SecurityRead}`);
    await scaleTradeRequest
      .get("securities/get/active/popular", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET ACTIVE SINGLE
export const getActiveSingleApi = (underlying) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${SecurityRead}&underlying=${underlying}`
    );
    await scaleTradeRequest
      .get("securities/get/active/single", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* ORDER SUBMISSION API */
// SUBMIT EQUITY ORDER
export const submitEquityOrderApi = (userId, data) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${OrderWrite}&userId=${userId}`
    );
    await scaleTradeRequest
      .post("orders/submit/equity", data, {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// SUBMIT CANCEL ORDER
export const submitCancelOrderApi = (userId, accountId, tradeId, data) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${OrderWrite}&userId=${userId}&accountId=${accountId}&tradeId=${tradeId}`
    );
    await scaleTradeRequest
      .post("orders/submit/cancel", data, {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// SUBMIT STRATEGIES
export const submitStrategiesOrderApi = (data) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(`key=${OrderWrite}`);
    await scaleTradeRequest
      .post("orders/submit/strategies", data, {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET USER REQUESTS ALL
export const getUserRequestsAllApi = (userId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${OrderRead}&userId=${userId}`
    );
    await scaleTradeRequest
      .get("orders/get/user/requests/all", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET USER SUBMISSIONS ALL
export const getUserSubmissionsAllApi = (userId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${OrderRead}&userId=${userId}`
    );
    await scaleTradeRequest
      .get("orders/get/user/submissions/all", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* STRATEGY INFORMATION API */
// USER STRATEGY SET
export const userStrategySetApi = (userId, data) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${StrategyWrite}&userId=${userId}`
    );
    await scaleTradeRequest
      .post("strategy/user/strategy/set", data, {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// USER STRATEGY SET
export const userStrategyStartApi = (userId, data) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${StrategyWrite}&userId=${userId}`
    );
    await scaleTradeRequest
      .post("strategy/user/strategy/start", data, {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// USER STRATEGY STOP
export const userStrategyStopApi = (userId, data) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${StrategyWrite}&userId=${userId}`
    );
    await scaleTradeRequest
      .post("strategy/user/strategy/stop", data, {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET USER STRATEGY ALL
export const getUserStrategyAllApi = (userId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${StrategyRead}&userId=${userId}`
    );
    await scaleTradeRequest
      .get("strategy/get/user/strategy/all", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// GET USER STRATEGY ACCOUNT
export const getUserStrategyAccountApi = (userId, accountId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${StrategyRead}&userId=${userId}&accountId=${accountId}`
    );
    await scaleTradeRequest
      .get("strategy/get/user/strategy/account", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        resolve(e);
      });
  });
};
// GET STRATEGY ALL ALLOCATIONS
export const getStrategyAllAllocationsApi = (strategy) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${StrategyRead}&strategy=${strategy}`
    );
    await scaleTradeRequest
      .get("strategy/get/all/strategy", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// USER STRATEGY SET
export const userResetRollOverApi = (userId, data) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${StrategyWrite}&userId=${userId}`
    );
    await scaleTradeRequest
      .post("strategy/user/strategy/start", data, {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
